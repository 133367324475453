.public-DraftEditorPlaceholder-root {
  color: #9197a3 !important;
  position: absolute !important;
  width: 100% !important;
  z-index: 1 !important;
  font-size: 24px !important;
  line-height: 40px !important;
}

.public-DraftEditorPlaceholder-hasFocus {
  color: #c5c5c5 !important;
}
